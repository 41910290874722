@media screen and (max-width: 900px) {
    section{
        background-size: cover !important;
        background-repeat: no-repeat !important;
        &.banner{
            background: url("../images/banner-bg-mobile.png");
            padding-top: 227.60%;
            .container{
                top: 5%;
                width: 100%;
                padding: 0 !important;
                .grab-moveit-logo{
                    width:  50%;
                }
                .funmilya-logo{
                    width: 100%;
                }
                p{
                    margin-top: 3%;
                    font-size: 4vw;
                    padding: 0 15%;
                    font-weight: bold;
                }
                .banner-activities-wrapper{
                    flex-direction: column;
                    margin-top: 0;
                    width: 100%;
                    img{
                        width: 40%;
                        height: auto;
                        margin: 0 auto 2%;
                    }
                }
            }
        }
        &.schedule{
            background: url("../images/shedule-bg-mobile.png");
            padding-top: 200vw;
            .container{
                top: 2%;
                width: 100%;
                padding: 0 !important;
                .hook{
                    // padding-left: 2vw;
                    .text-container{
                        // width: 95%;
                        margin: auto;
                        p{
                            margin-top: 1rem;
                            font-size: 3.5vw !important;
                            font-weight: bold;
                        }
                    }
                }
                .table-wrapper{
                    grid-template-columns: 1fr !important;
                    margin: 0;
                    width: 100%;
                    top: -3vw;
                    table{
                        width: 100%;
                        margin: 0;
                        &:last-child{
                            tr:first-child{td{padding-top: 0 !important;}}
                        }
                        tr:first-child{
                            td{
                                border-top: none !important;
                                padding-top: 6vw !important;
                            }}
                        td{
                            font-size: 3vw;
                            border-radius: 0 !important;
                            background: rgba(0, 0, 0, 0.5);
                            &:first-child{
                                border-left: none;
                            }
                            &:last-child{
                                border-right: none;
                            }
                        }
                    }
                }
            }
        }
        &.activities{
            background: url("../images/activities-bg-mobile.png");
            padding-top: 336.80%;
            img{
                max-width: 100%;
            }
            .container{
                top: 1%;
                p{
                    filter: drop-shadow(-4px 4px 3px rgba(0, 0, 0, .75));
                    font-size: 3vw;
                    font-weight: bold;
                    padding: 0 9vw;
                    margin: 2% 0 3%;
                }
                .title{
                    height: 25vw;
                }
                .kv{
                    width: 85%;
                    margin-bottom: 5%;
                }
            }
        }
        &.reminders{
            background: url("../images/reminders-bg-mobile.png");
            padding-top: 657.27%;
            .container{
                top: 2.5%;
                width: 95%;
                .title{
                    width: 100%;
                    height: auto;
                }
                h1{
                    font-size: 4.5vw;
                    // filter: drop-shadow(-4px 4px 3px rgba(0, 0, 0, .5));
                    font-weight: bold;
                    h3{
                        margin-top: 0;
                    }
                }
                h3{
                    font-size: 3.25vw;
                    font-weight: bold;
                    margin-top: 12%;
                    // filter: drop-shadow(-4px 4px 3px rgba(0, 0, 0, .5));
                }
                .reminders-photo{
                    width: 83%;
                }
            }
        }
        &.faq{
            padding-top: 3%;
            background-size: 100% auto;
            .bgTopper{
                height: 30vw;
                width: 100%;
                object-fit: cover;
            }
            .container{
                .title{
                    width: 50%;
                    height: auto;
                }
                button{
                    font-size: 2.5vw !important;
                    padding: 1vw 3vw !important;
                }
                .faq-answer{
                    font-size: 2.5vw !important;
                    padding-left: 2.25vw;
                }
                b{
                    font-size: 2.5vw !important;
                }
                table{
                    width: 100% !important;
                    tr{
                        width: 100%;
                        display: grid;
                        grid-template-columns: 1fr;
                        td{
                            width: 100% !important;
                            background: rgba(0, 0, 0, 0.05) !important;
                            text-align: center;
                            border: none !important;
                            border-left: 2px solid white !important;
                            border-right: 2px solid white !important;
                            border-radius: 0 !important;
                            font-weight: bold;
                            line-height: 1.1;
                            &:first-child{
                                padding-top: 3% !important;
                                padding-bottom: 0 !important;
                            }
                            &:last-child{
                                padding-top: 0 !important;
                                padding-bottom: 3% !important;
                            }
                            ul{
                                list-style: none;
                            }
                        }
                        &:first-child{
                            td:first-child{
                                border-top: 2px solid white !important;
                                border-radius: 20px 20px 0 0 !important;
                            }
                            td:last-child{
                            
                            }
                        }
                        &:last-child{
                            td:first-child{
                                border-top: 2px solid white !important;
                            }
                            td:last-child{
                                border-bottom: 2px solid white !important;
                                border-radius: 0 0 20px 20px !important;
                            }
                        }
                    }
                }
            }
        }
        &.partners{
            height: auto;
            padding: 0;
            background: url("../images/partners-bg-mobile.png");
            .container{
                position: relative;
                margin: 0;
                padding-top: 15%;
                .header{
                    h1{
                        font-weight: bold;
                        font-size: 8vw;
                        margin: 0 !important;
                        filter: drop-shadow(-4px 4px 3px rgba(0, 0, 0, .5));
                    }
                    h2{
                        font-weight: bold;
                        font-size: 5.5vw;
                        margin-bottom: 10vw !important;
                        filter: drop-shadow(-4px 4px 3px rgba(0, 0, 0, .5));
                    }
                }
                .partner-logos{
                    grid-template-columns: 1fr;
                    grid-template-rows: 78vw 125vw 90vw;
                    grid-template-areas: "a" "b" "c";
                    .title{
                        height: 10vw !important;
                        width: auto !important;
                        filter: drop-shadow(-4px 4px 3px rgba(0, 0, 0, .5));
                    }
                    .copresenters{
                        grid-area: a;
                        // height: 75vw;
                        .title{
                            height: 9vw !important;
                        }
                        .logos{
                            width: 60% !important;
                        }
                    }
                    .major{
                        grid-area: b;
                        .logos{
                            width: 80% !important;
                        }
                    }
                    .minor{
                        grid-area: c;
                        .logos{
                            width: 95% !important;
                        }
                    }
                    .wrapper{
                        padding: 0 !important;
                    }
                    .divider{
                        display: none;
                    }
                }
            }
        }
    }
}