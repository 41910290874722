@import url("https://cdn.jsdelivr.net/npm/font-awesome@4.7.0/css/font-awesome.css");
@font-face {
    font-family: "GrabV2";
    src: url("../fonts/grab/GrabCommunityENv2Inline.ttf") format("truetype"),
        url("../fonts/grab/GrabCommunityENv2_woff.woff") format("woff"),
        url("../fonts/grab/GrabCommunityENv2_woff2.woff2") format("woff2");
}

$theme-green: #00b14f;
$theme-green-light: #d9fcde;
$theme-green-dark: #003932;
$theme-green-dark-mid: #005339;
$theme-orange: #ffb800;
$theme-orange-dark: #ff8501;
$theme-salmon: #ff806b;
$theme-yellowGreen: #d3f035;
$theme-cyan: #005673;
$theme-violet: #5a3298;
$theme-yellowSoft: #ffe0a1;
$theme-yellowSoft-dark: #ffbc03;

$category-green: #07b958;
$category-yellow: #d9f23e;
$category-orange: #ffc000;
$category-redorange: #ff8403;
$category-blue: #37d2db;
$category-purple: #efc6fc;
$category-red: #ff806b;

$regionL-txt: #ff826c;
$regionL-bg: #ffeaff;
$regionV-txt: #ffb902;
$regionV-bg: #5647a3;
$regionM-txt: #ffe0a1;
$regionM-bg: #352374;

body,
html{
    font-family: 'Grab Medium', Arial, sans-serif;
    font-size: 22px;
    font-weight: 450;
    scroll-behavior: smooth;
    background: rgb(13,101,46);
    max-width: 1920px;
    *{
        font-family: 'Grab Medium', Arial, sans-serif;
    }
}

table{
    border-collapse: unset !important;
    border-spacing: 0;
}

section{
    height: 0;
    padding-top: 60%;
    text-align: center;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .container{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        max-width: unset;
    }
    &.banner{
        padding-top: 65.11%; 
        background-image: url("../images/banner-bg.png");
        .container{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            top: 3%;
            color: white;
            .grab-moveit-logo{
                width: 30%;
                margin-bottom: 30px;
            }
            .funmilya-logo{
                width: 85%;
            }
            p{
                position: relative;
                margin-top: -3%;
                font-size: 1.3vw;
                filter: drop-shadow(-4px 4px 3px rgba(0, 0, 0, .5));
            }
            .banner-activities-wrapper{
                display: flex;
                margin-top: 13%;
                img{
                    height: 8vw;
                    margin: 0 5px;
                }
            }
        }
    }  
    &.schedule{
        padding-top: 60%; 
        background-image: url("../images/shedule-bg.png");
        color: white;
        .container{
            top: 8%;
            width: 90%;
            .hook{
            //     background: url("../images//schedule-OpeningBg.png") no-repeat;
            //     background-size: cover;
            //     height: 0;
            //     padding-top:  30.87%;
            //     position: relative;
            //     z-index: 1;
                filter: drop-shadow(-4px 4px 10px rgba(0, 0, 0, .5));
                position: relative;
                z-index: 1;
                .text-container{
                    // position: absolute;
                    // height: 100%;
                    // width: 100%;
                    // top: 0;
                    // left: 0;
                    background: #e1ff21;
                    border: 10px solid transparent;
                    border-radius: 2vw;
                    background: linear-gradient(to right, #e1ff21, #e1ff21), linear-gradient(-60deg, #277e0b 0%, #277e0b 30%, #22ef2e 50%, #277e0b 70%, #277e0b 100%);
                    background-clip: padding-box, border-box;
                    background-origin: padding-box, border-box;
                    filter: drop-shadow(-5px 5px 0 #0ab114);
                    p{
                        // position: absolute;
                        // width: 100%;
                        // top: 50%;
                        // left: 0;
                        // transform: translateY(-50%);
                        font-size: 2vw;
                        // color: $theme-green;
                        color: #02af4d;
                        margin-top: 1rem;
                    }
                }
            }
        }
        .table-wrapper{
            width: 100%;
            border-spacing: 0;
            position: relative;
            top: -1.5vw;
            width: 95%;
            margin: auto;
            display: grid;
            grid-template-columns: 1fr 1fr;
            table{
                margin: 0 1%;
                td{
                    border: 2px solid white;
                    // padding: 5px;
                    width: 50%;
                    background: transparentize($color: #000000, $amount: .5);
                    font-size: 1.5vw;
                    &:last-child{
                        font-size: 1.25vw;
                    }
                }
                tr:first-child td{padding-top: 40px;}
                tr:not(:first-child) td{border-top: 0;}
                tr td:last-child{border-left: 0;}
                tr:first-child td:first-child {border-top-left-radius: 20px;}
                tr:first-child td:last-child {border-top-right-radius: 20px;}
                tr:last-child td:first-child {border-bottom-left-radius: 20px;}
                tr:last-child td:last-child {border-bottom-right-radius: 20px;}
            }
        }
    }  
    &.activities{
        padding-top: 54.04%; 
        background-image: url("../images/activities-bg.png");
        color: white;
        .container{
            width: 100%;
            top: 11.5%;
            .activities-table{
                width: 100%;
                font-size: 1.1vw;
                tr:not(.tr-kv){
                    td{
                        padding: 0 5%;
                    }
                }
                td{
                    width: 33.3%;
                    vertical-align: baseline;
                }
                .title{
                    // height: 120px;
                    // width: 80%;
                    max-width: 100%;
                    max-height: 150px;
                    object-fit: contain;
                    // padding: 0 40px 0 30px;
                    padding-right: 10px;
                    padding-bottom: 10px;
                }
                p{
                    // min-height: 150px;
                    // padding: 30px;
                    font-size: .95vw;
                    font-family: "Grab Medium";
                    margin-top: 0;
                    margin-bottom: 1%;
                    filter: drop-shadow(-4px 4px 3px rgba(0, 0, 0, .6));
                }
                .kv{
                    width: 100%;
                }
            }
        }
    }  
    &.reminders{
        padding-top: 70.43%; 
        background-image: url("../images/reminders-bg.png");
        .container{
            top: 6%;
            color: white;
            width: 80%;
        }
        .title{
            height: 6.5vw;
            margin-bottom: 2%;
        }
        h1{
            font-size: 1.75vw;
        }
        h3{
            padding: 2% 0;
            font-size: 1.15vw;
        }
        img{
            max-width: 100%;
        }
        .reminders-photo{
            width: 95%;
        }
        b{
            font-family: "Grab Heavy";
        }
    }
    &.faq{
        height: auto;
        padding: 5%;
        background: url("../images/faq-bg.png");
        background-position: bottom center;
        background-size: cover;
        padding-top: 2.5%;
        .bgTopper{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
        .container{
            position: relative;
            z-index: 1;
            min-height: 600px;
            margin: 0;
            .title{
                height: 6.5vw;
                margin-bottom: 3%;
            }
            .accordion{
                width: 100%;
                background: none !important;
                border: none !important;
                outline: none !important;
                .accordion-item{
                    margin-bottom: 2%;
                    background: none !important;
                    border: none !important;
                    outline: none !important;
                    text-align: left;
                    .faq-button{
                        .accordion-header{
                            background: none !important;
                            border: none !important;
                            outline: none !important;
                            display: inline-block;
                            button{
                                // border: none !important;
                                outline: none !important;
                                box-shadow: none !important;
                                // background: white;
                                padding: 1vw 1vw!important;
                                border-radius: 100px;
                                color: $theme-green;
                                font-size: 1.25vw;
                                font-family: "Grab Medium";
                                font-weight: bold;
                                border: 4px solid transparent;
                                border-radius: 100px;
                                background: 
                                    linear-gradient(to right, white, white), 
                                    linear-gradient(-60deg,#4a8b28 0%, #4a8b28 30%,#69e84d 50%,#4a8b28 70%, #4a8b28 100%); 
                                background-clip: padding-box, border-box;
                                background-origin: padding-box, border-box;
                                filter: drop-shadow(-5px 3px 0 #55b23a);
                            }
                        }
                    }

                    .accordion-body{
                        padding-top: 1%;
                        padding-bottom: 2%;
                        padding-left: 1.75%;
                        .faq-answer{
                            font-family: "Grab Medium";
                            // font-weight: bold;
                            color: white;
                            font-size: 1.1vw;
                            b{
                                font-weight: bold;
                                font-size: 1.2vw;
                            }
                            table{
                                table-layout: fixed;
                                width: 60%;
                                td{
                                    border: 2px solid white;
                                    width:auto;
                                    text-wrap: nowrap;
                                    background: transparentize($color: #000000, $amount: .75);
                                    padding: 1%;
                                    ul{
                                        padding-left: 20px;
                                        margin: 0;
                                        list-style: none;
                                    }
                                }
                                tr td:first-child{
                                    width: 40%;
                                }
                                tr td:last-child{
                                    width: 70%;
                                }
                                tr:not(:first-child) td{border-top: 0;}
                                tr td:last-child{border-left: 0;}
                                tr:first-child td:first-child {border-top-left-radius: 20px;}
                                tr:first-child td:last-child {border-top-right-radius: 20px;}
                                tr:last-child td:first-child {border-bottom-left-radius: 20px;}
                                tr:last-child td:last-child {border-bottom-right-radius: 20px;}
                            }
                        }
                    }
                }
            }
        }
    }
    &.partners{
        padding-top: 70.43%; 
        background-image: url("../images/partners-bg.jpg");
        .container{
            color: white;
            top: 12%;
            width: 95%;
            line-height: 1;
            h1{
                font-size: 6.5vw;
                margin-bottom: 10px;
                filter: drop-shadow(-4px 4px 3px rgba(0, 0, 0, .5));
                font-weight: bold;
            }
            h2{
                font-size: 4vw;
                filter: drop-shadow(-4px 4px 3px rgba(0, 0, 0, .5));
                font-weight: bold;
            }
            .partner-logos{
                display: grid;
                grid-template-columns: 1fr 11px 38% 11px 1fr;
                margin-top: 1%;
                .divider{
                    padding-top: 6vw;
                    padding-bottom: 30px;
                    div{
                        background: white;
                        width: 3px;
                        height: 100%;
                    }
                }
                .wrapper{
                    padding: 20px;
                    .title{
                        height: 10%;
                        margin-bottom: 5%;
                        object-fit: contain;
                    }
                    &.major{
                        padding-top: 25%;
                        .logos{
                            width: 90%;
                        }
                    }
                    &.minor{
                        padding-top: 25%;
                        .logos{
                            width: 100%;
                        }
                    }
                    &.copresenters{
                        // padding: 20px 40px;
                        .title{
                            width: 95%;
                        }
                        .logos{
                            margin: 0 auto 20px;
                            width: calc(80% - 40px);
                        }
                    }
                }
                img{
                    max-width: 100%;
                }
            }
        }
    }
}